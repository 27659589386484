import { graphql, Link } from "gatsby";
import * as React from "react";
import { CONSTANTS } from "../components/constants";
import Image from "../components/image";
import Layout from "../components/layout";
import Pagination from "../components/pagination";
import Seo from "../components/seo";

export const shopPageQuery = graphql`
  query shopPageData($strapiIds: [Int]!) {
    products: allStrapiProducts(filter: { strapiId: { in: $strapiIds } }) {
      nodes {
        strapiId
        slug
        shortDescription
        price
        discountedPrice
        name
        linkText
        link
        interviewRelatedDescription
        description
        brand {
          name
        }
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 590
                quality: 90
                aspectRatio: 1
                transformOptions: { cropFocus: CENTER, trim: 0 }
              )
            }
            extension
            publicURL
          }
          caption
          alternativeText
        }
      }
      totalCount
    }
  }
`;

const Post = ({ pageContext, data }) => {
  return (
    <Layout altBg={true}>
      <Seo
        title="Shop"
        // description= TODO: Shop SEO description!
        meta={[
          {
            property: `og:url`,
            content: `${CONSTANTS.URL}/shop/`,
          },
        ]}
      />
      <div className="section">
        <div className="row gtr-150 gtr-uniform">
          {data.products.nodes.map((product) => (
            <div className="col-4 col-6-medium" key={product.strapiId}>
              <span className="image fit overlay">
                <Link to={`/shop/${product.slug}`} className="no-border">
                  {product.images.length > 0 ? (
                    <Image image={product.images.length && product.images[0]} />
                  ) : (
                    //   No image place holder!
                    <>
                      <div
                        data-gatsby-image-wrapper=""
                        className="gatsby-image-wrapper"
                      >
                        <div
                          aria-hidden="true"
                          style={{
                            paddingTop: "100%",
                            backgroundColor: "#efefef",
                          }}
                        ></div>
                      </div>
                    </>
                  )}
                </Link>
              </span>
              <div className="item-title">
                <Link to={`/shop/${product.slug}`}>
                  <h3>{product.brand?.name}</h3>
                  <h4>{product.name}</h4>
                  <p className="no-margin no-padding">
                    {product.price && (
                      <>
                        {product.discountedPrice && (
                          <del>${product.price?.toFixed(2)}</del>
                        )}{" "}
                        <strong>
                          $
                          {product.discountedPrice?.toFixed(2) ||
                            product.price?.toFixed(2)}
                        </strong>
                      </>
                    )}
                  </p>
                </Link>
              </div>
            </div>
          ))}
        </div>
        {pageContext.totalCount > 9 && (
          <div className="row aln-center xlarge-top-margin">
            <Pagination
              {...{
                url: `/shop/page-`,
                totalCount: pageContext.totalCount,
                pageSize: 9,
                siblingCount: 1,
                currentPage: pageContext.page,
                firstPageSpecial: "/shop/",
              }}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Post;
