import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";

const Image = ({ image }) => {
  const gatsbyImage = image.localFile ? getImage(image.localFile) : getImage(image);
  if (gatsbyImage) {
    return <GatsbyImage image={gatsbyImage} alt={image.alternativeText || ""} />;
  }
  if (!gatsbyImage && image.localFile.extension === "svg") {
    return <img src={image.localFile.publicURL} alt={image.alternativeText} />;
  }
  return "";
};

export default Image;
